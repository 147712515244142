import React, { useState, useEffect, useContext } from "react";
import "../../assets/style/globalStyle.scss";
import { navigate } from "gatsby";
import {
  getAllUserRequests,
  getUserRequestById,
} from "../../services/userRequestService";

import { GlobalDispatchContext } from "../../context/GlobalContextProvider";

const AllUserRequests = () => {
  const dispatch = useContext(GlobalDispatchContext);

  const [isRequestToDoShow, setIsRequestToDoShow] = useState(true);
  const [isRequestDoneShow, setIsRequestDoneShow] = useState(false);
  const [isRequestCanceledShow, setIsRequestCanceledShow] = useState(false);
  const [requestDone, setRequestDone] = useState([]);
  const [requestToDo, setRequestToDo] = useState([]);
  const [requestCanceled, setRequestCanceled] = useState([]);

  const getAllUserRequestsToCRM = async () => {
    const data = await getAllUserRequests();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>OOOOOOOOHHHHHHHH DAMN</h1>
        </div>
      );
    }

    for (let i = 0; i < data.length; i++) {
      let userRequest = data[i];
      if (userRequest.status === "Annulée") {
        setRequestCanceled((RequestCanceled) => [
          ...RequestCanceled,
          userRequest,
        ]);
      } else if (userRequest.status === "Traitement en cours") {
        setRequestToDo((RequestToDo) => [...RequestToDo, userRequest]);
      } else {
        setRequestDone((RequestDone) => [...RequestDone, userRequest]);
      }
    }
  };

  const GoToRequest = async (id) => {
    const userRequest = await getUserRequestById(id);
    dispatch({
      type: "get_user_request",
      payload: userRequest,
    });
    navigate("/app/answerUserRequestPage");
  };

  const renderRequestCard = (userRequest) => {
    const body = userRequest.content.slice(-1)[0].body;
    return (
      <tr key={userRequest._id} style={{ height: "45px" }}>
        <td>{userRequest.subject}</td>
        <td>{userRequest.title}</td>
        <td>
          {new Intl.DateTimeFormat("fr-FR").format(
            Date.parse(userRequest.createdAt)
          )}
        </td>
        <td className="is-hidden-mobile">
          {body.length > 50 ? `${body.slice(0, 47)}...` : body}
        </td>
        <td className="is-hidden-mobile">
          {userRequest.author.userName
            ? userRequest.author.userName
            : userRequest.author.firstName}
        </td>
        <td className="is-hidden-mobile">
          {userRequest.content.slice(-1)[0].author ===
          "5ed7a8c1f35fb0cc6fc54eac"
            ? "Ball'N Connect Support"
            : userRequest.author.userName
            ? userRequest.author.userName
            : userRequest.author.firstName}
        </td>
        <td>
          <button
            className="button is-primary"
            onClick={() => GoToRequest(userRequest._id)}
          >
            <span className="icon is-medium">
              <span className="fa-stack">
                <i className="fas fa-circle fa-stack-2x"></i>
              </span>
            </span>
          </button>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    getAllUserRequestsToCRM();
  }, []);

  return (
    <div>
      <div className="box">
        <div className="columns">
          <div className="column">
            <p className="label">Demandes à traiter</p>
          </div>
          <div className="column">
            <button
              className={
                isRequestToDoShow
                  ? "button is-danger is-outlined"
                  : "button is-primary is-outlined"
              }
              onClick={() => {
                setIsRequestToDoShow(!isRequestToDoShow);
              }}
            >
              {isRequestToDoShow
                ? "Masquer les demandes à traiter"
                : "Afficher les demandes à traiter"}
            </button>
          </div>
        </div>
        {isRequestToDoShow && (
          <table className="table is-striped is-hoverable is-responsive">
            <thead>
              <tr>
                <th>
                  <abbr title="Sujet">Sujet</abbr>
                </th>
                <th style={{ width: "160px" }}>
                  <abbr title="Titre">Titre</abbr>
                </th>
                <th>
                  <abbr title="Date">Date</abbr>
                </th>
                <th className="is-hidden-mobile" style={{ width: "160px" }}>
                  <abbr title="Contenu">Contenu</abbr>
                </th>
                <th className="is-hidden-mobile">
                  <abbr title="Auteur">Auteur</abbr>
                </th>
                <th className="is-hidden-mobile">
                  <abbr title="Auteur dernier message">
                    Auteur dernier message
                  </abbr>
                </th>
              </tr>
            </thead>

            <tbody>
              {requestToDo.length !== 0 &&
                requestToDo
                  .sort(
                    (a, b) =>
                      new Date(b.createdAt.slice(0, 10)) -
                      new Date(a.createdAt.slice(0, 10))
                  )
                  .map(renderRequestCard)}
            </tbody>
          </table>
        )}
      </div>

      <div className="box">
        <div className="columns">
          <div className="column">
            <p className="label">Demandes terminées</p>
          </div>
          <div className="column">
            <button
              className={
                isRequestDoneShow
                  ? "button is-danger is-outlined"
                  : "button is-primary is-outlined"
              }
              onClick={() => {
                setIsRequestDoneShow(!isRequestDoneShow);
              }}
            >
              {isRequestDoneShow
                ? "Masquer les demandes terminées"
                : "Afficher les demandes terminées"}
            </button>
          </div>
        </div>
        {isRequestDoneShow && (
          <table className="table is-striped is-hoverable is-responsive">
            <thead>
              <tr>
                <th>
                  <abbr title="Sujet">Sujet</abbr>
                </th>
                <th style={{ width: "160px" }}>
                  <abbr title="Titre">Titre</abbr>
                </th>
                <th>
                  <abbr title="Date">Date</abbr>
                </th>
                <th className="is-hidden-mobile" style={{ width: "160px" }}>
                  <abbr title="Contenu">Contenu</abbr>
                </th>
                <th className="is-hidden-mobile">
                  <abbr title="Auteur">Auteur</abbr>
                </th>
                <th className="is-hidden-mobile">
                  <abbr title="Auteur dernier message">
                    Auteur dernier message
                  </abbr>
                </th>
              </tr>
            </thead>

            <tbody>
              {requestDone.length !== 0 &&
                requestDone
                  .sort(
                    (a, b) =>
                      new Date(b.createdAt.slice(0, 10)) -
                      new Date(a.createdAt.slice(0, 10))
                  )
                  .map(renderRequestCard)}
            </tbody>
          </table>
        )}
      </div>

      <div className="box">
        <div className="columns">
          <div className="column">
            <p className="label">Demandes annulées</p>
          </div>
          <div className="column">
            <button
              className={
                isRequestCanceledShow
                  ? "button is-danger is-outlined"
                  : "button is-primary is-outlined"
              }
              onClick={() => {
                setIsRequestCanceledShow(!isRequestCanceledShow);
              }}
            >
              {isRequestCanceledShow
                ? "Masquer les demandes annulées"
                : "Afficher les demandes annulées"}
            </button>
          </div>
        </div>
        {isRequestCanceledShow && (
          <table className="table is-striped is-hoverable is-responsive">
            <thead>
              <tr>
                <th>
                  <abbr title="Sujet">Sujet</abbr>
                </th>
                <th style={{ width: "160px" }}>
                  <abbr title="Titre">Titre</abbr>
                </th>
                <th>
                  <abbr title="Date">Date</abbr>
                </th>
                <th className="is-hidden-mobile" style={{ width: "160px" }}>
                  <abbr title="Contenu">Contenu</abbr>
                </th>
                <th className="is-hidden-mobile">
                  <abbr title="Auteur">Auteur</abbr>
                </th>
                <th className="is-hidden-mobile">
                  <abbr title="Auteur dernier message">
                    Auteur dernier message
                  </abbr>
                </th>
              </tr>
            </thead>

            <tbody>
              {requestCanceled.length !== 0 &&
                requestCanceled
                  .sort(
                    (a, b) =>
                      new Date(b.createdAt.slice(0, 10)) -
                      new Date(a.createdAt.slice(0, 10))
                  )
                  .map(renderRequestCard)}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default AllUserRequests;
